<template>
  <div>
    <div v-if="!loading">
      <div class="history-list">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Action</th>
                <th class="text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in history" :key="index">
                <td>{{ parseActivity(item) }}</td>
                <td>
                  <date-hover :date="item.timestamp" bottom></date-hover>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <div v-else class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
        size="40"
        width="5"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { eventRef } from '@/firebase/db'
export default {
  props: ['parcel'],
  data: () => ({
    history: [],
    loading: true
  }),
  methods: {
    async setHistory() {
      this.history = []
      this.loading = true
      let q = eventRef
        .where('parcel', '==', this.parcel.id)
        .where('type', '!=', 'navigation')
      q = await q.get()
      q.forEach(item => {
        let d = item.data()
        this.history.push(d)
      })
      this.history.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
      this.loading = false
    }
  },
  watch: {
    parcel: {
      immediate: true,
      handler: 'setHistory'
    }
  }
}
</script>

<style scoped>
.history-list {
  overflow-y: scroll;
  max-height: 60vh !important;
}
</style>
